import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import styles from './styles.module.css';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import HomeIcon from '@mui/icons-material/Home';
import { Grid } from '@material-ui/core';
import { USER_PERMISSIONS, ROUTES } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { ThemeContext } from '../../../Context/ThemeContext';

export default function NavBar() {
  const { selectedTab, setSelectedTab } = useContext(selectedTabContext);
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);

  const tabStyleActive = {
    backgroundColor: theme.color.active,
    color: '#fff',
  };

  const tabStyle = {};

  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const itemTabRoutes = ['items', 'categories'];
  const salesTabRoutes = ['item-summary', 'bill-summary', 'itemised-bill'];
  return (
    <Grid container>
      <Grid item xs={12} className={styles.navBar}>
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            background: theme.color.tertiary,
          }}
        >
          <span
            style={{ color: theme.color.sub_header }}
            className={styles.Shop}
          >
            {shop && shop.shop_name}
          </span>
        </div>
        <div
          style={{ background: theme.color.tertiary }}
          className={styles.profileSec}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <PersonIcon
                style={{ color: theme.color.sub_header, fontSize: '12px' }}
              />
            </span>
            <span
              style={{ color: theme.color.sub_header, width: 48 }}
              className={styles.username}
            >
              Username
            </span>
            <span className={styles.username}>: {user && user.username}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <HomeIcon
                style={{ color: theme.color.sub_header, fontSize: '12px' }}
              />
            </span>
            <span
              style={{ color: theme.color.sub_header, width: 48 }}
              className={styles.username}
            >
              Shop ID
            </span>
            <span className={styles.username}>: {shop && shop?.id}</span>
          </div>
        </div>

        {((user || {}).shop_permissions || {}).reports_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.DASHBOARD}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'dashboard' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('dashboard');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <DashboardIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Dashboard</span>
            </div>
          </Link>
        )}
        {((user || {}).shop_permissions || {}).products_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <div
            className={styles.navItem}
            style={
              itemTabRoutes.includes(selectedTab) || selectedTab === 'itemTab'
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleSelectedTabChange('itemTab');
            }}
          >
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <LocalOfferIcon
                fontSize="small"
                className={styles.navBarIconColor}
              />
            </span>
            <span className={styles.title}>Items</span>
          </div>
        )}
        {itemTabRoutes.includes(selectedTab) || selectedTab === 'itemTab' ? (
          <div
            style={{
              backgroundColor: theme.color.active_bg,
              padding: '4px',
            }}
          >
            <Link
              to={ROUTES.ITEMS}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Products</span>
              </div>
            </Link>
            <Link
              to={ROUTES.CATEGORIES}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Categories</span>
              </div>
            </Link>
          </div>
        ) : null}
        {((user || {}).shop_permissions || {}).customers_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.CUSTOMERS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'customer' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('customer');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <PeopleIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Customers</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).purchase_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.VENDORS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={styles.navItem}
              style={selectedTab === 'vendors' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('vendors');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <InventoryIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Vendors</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).customers_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.SALES_PERSON}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'salesperson' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('salesperson');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <PersonIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Salespersons</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).customers_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.TABLES}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'tables' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('tables');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <TableRestaurantIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Tables</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).purchase_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.PURCHASE_HISTORY}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={styles.navItem}
              style={
                selectedTab === 'purchase-history' ? tabStyleActive : tabStyle
              }
              onClick={() => {
                handleSelectedTabChange('purchase-history');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <ShoppingCartIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Purchases</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).expense_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.EXPENSES}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={styles.navItem}
              style={selectedTab === 'expenses' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('expenses');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <CreditCardIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Expenses</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).damage_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.DAMAGE_HISTORY}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={
                selectedTab === 'damage-history' ? tabStyleActive : tabStyle
              }
              onClick={() => {
                handleSelectedTabChange('damage-history');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <AssessmentIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Stock Damages</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).stock_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.STOCK_REPORT}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'stock-report' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('stock-report');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <SyncAltIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Stock Reports</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).reports_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <div
            className={`${styles.navItem} ${styles.mt - 1}`}
            style={
              salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab'
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleSelectedTabChange('salesTab');
            }}
          >
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <AssignmentIcon
                fontSize="small"
                className={styles.navBarIconColor}
              />
            </span>
            <span className={styles.title}>Sales Reports</span>
          </div>
        )}
        {salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab' ? (
          <div
            style={{
              backgroundColor: theme.color.active_bg,
              padding: '4px',
            }}
          >
            <Link
              to={ROUTES.BILL_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Sales Summary</span>
              </div>
            </Link>

            <Link
              to={ROUTES.ITEMISED_BILL}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Sales Items</span>
              </div>
            </Link>

            <Link
              to={ROUTES.ITEM_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Item Summary</span>
              </div>
            </Link>
            <Link
              to={ROUTES.PAYMENT_MODE_REPORT}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Payments Summary</span>
              </div>
            </Link>

            <Link
              to={ROUTES.PROFIT_BILL_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Profit Sales Summary</span>
              </div>
            </Link>
            <Link
              to={ROUTES.PROFIT_ITEMISED_BILL}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Profit Sales Items</span>
              </div>
            </Link>
            <Link
              to={ROUTES.PROFIT_ITEM_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Profit Item Summary</span>
              </div>
            </Link>
          </div>
        ) : null}
        {((user || {}).shop_permissions || {}).devices_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.TERMINALS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'terminals' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('terminals');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <DevicesIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Terminals</span>
            </div>
          </Link>
        )}
        {((user || {}).shop_permissions || {}).shop_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.SETTINGS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'settings' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('settings');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <SettingsIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Settings</span>
            </div>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}
