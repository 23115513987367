import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import {
  Grid,
  Stepper,
  Box,
  StepLabel,
  Step,
  StepContent,
  Button,
  Typography,
} from '@mui/material';
import logo from '../../../images/Logo.png';
import SanoftLogo from '../../../images/sanoft_logo_512.png';
import PlaystoreIcon from '../../../images/google-playstore-icon.png';
import { ROUTES } from '../../../const';

function AppSetup() {
  const [activeStep, setActiveStep] = useState(0);

  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    history.push(ROUTES.SHOP_CREATED);
  };

  const steps = [
    {
      label: 'Install Apps',
      component: <InstallApp />,
    },
    {
      label: 'Printer Settings',
      component: <PrintSettings />,
    },
    {
      label: 'Test Printer',
      component: <TestPrinter />,
    },
    {
      label: 'Add Products',
      component: <AddProducts />,
    },
    {
      label: 'Create Invoice',
      component: <InvoiceCreation />,
    },
    {
      label: 'View Report',
      component: <ViewReports />,
    },
  ];

  return (
    <Grid container className={styles.setupWrapper}>
      <Grid item xs={12}>
        <img src={logo} alt="logo" style={{ height: 40 }} />
      </Grid>
      <Grid item xs={12} className={styles.onboardingContainer} mt={4}>
        <h1>Set up the app based on your needs</h1>
      </Grid>
      <Grid
        item
        xs={12}
        className={styles.onboardingContainer}
        style={{ color: '#8b8b8b' }}
      >
        <h3>You can always change them later.</h3>
      </Grid>
      <Grid item xs={12} className={styles.stepContainer}>
        <Box maxWidth={800}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  {step.component}
                  <Box>
                    <div>
                      <Button
                        variant="contained"
                        onClick={
                          index === steps.length - 1 ? handleFinish : handleNext
                        }
                        sx={{ mt: 1, mr: 1, padding: '5px 15px !important' }}
                        className={styles.btn}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
}

const StepList = ({ steps }) => {
  return (
    <>
      {steps.map((step, index) => (
        <Grid item xs={12} key={index} className={styles.steps}>
          <Typography variant="subtitle2" style={{ height: '100%' }}>
            {index + 1}:
          </Typography>
          <Typography className={styles.subText}> {step} </Typography>
        </Grid>
      ))}
    </>
  );
};

const InstallApp = () => {
  const handleInstallAppClick = (appType) => {
    const playStoreLink = apps[appType]['app_link'];
    window.open(playStoreLink, '_blank');
  };

  const apps = {
    'pos App': {
      description: `The POS App is designed for seamless and efficient sale creation. 
      As a counter staff member, use this app to quickly generate sales, 
      manage items, and complete transactions with ease. Simplify the 
      sales process and enhance your workflow with the POS App.`,
      app_link:
        'https://play.google.com/store/apps/details?id=com.sanoft.sanoftpos.legacy&pcampaignid=web_share',
    },
    'admin App': {
      description: `The Admin App is your go-to tool for in-depth sales tracking and 
      detailed analytics. Access comprehensive sales reports, monitor 
      performance, and gain valuable insights into your business. As an 
      administrator, use the Admin App to make informed decisions and 
      optimize your operations.`,
      app_link:
        'https://play.google.com/store/apps/details?id=com.sanoft.poslegacy&pcampaignid=web_share',
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={2}>
        <img src={SanoftLogo} alt="logo" className={styles.logo} />
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid container rowSpacing={1}>
          {Object.keys(apps).map((appType) => (
            <Grid
              item
              xs={12}
              className={styles.setupContainer}
              mb={1}
              key={appType}
            >
              <Typography variant="button" display="flex" gutterBottom>
                {appType.toUpperCase()}
              </Typography>
              <Typography className={styles.subText}>
                {apps[appType]['description']}
              </Typography>
              <Button
                variant="contained"
                className={styles.downloadBtn}
                style={{ marginTop: '10px' }}
                onClick={() => handleInstallAppClick(appType)}
              >
                <img
                  src={PlaystoreIcon}
                  alt="icon"
                  className={styles.btnIcon}
                />{' '}
                Download {appType.toLowerCase()}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PrintSettings = () => {
  const printerSteps = [
    'Launch the POS App on your device and login with username and password that you provided to create sanoft account',
    'Click on the "More" option located in the top-right corner of the app.',
    'In the menu, click on "Manage," and then select "Printer."',
    'In the Printer page, locate the "Invoice Printer" section. Here, you can set the printer type and choose the preferred invoice format.',
    'Continue to the KOT Format settings to customize the Kitchen Order Ticket format.',
    'In the Barcode Printer section, set the printer type and choose the desired barcode format.',
  ];

  return (
    <Grid container spacing={1} className={styles.setupContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center !important' }}>
        <Typography variant="button" fontWeight={600}>
          Printer Settings
        </Typography>
        <Typography className={styles.subText}>
          Now that you've installed the POS App, follow these steps to configure
          your printer settings:
        </Typography>
      </Grid>
      <StepList steps={printerSteps} />
    </Grid>
  );
};

const TestPrinter = () => {
  const testPrinterSteps = [
    'Navigate to Printer Settings by follow the instructions from Step 2',
    'In the "Invoice Printer" section, click on the "Test Printer" button to ensure proper functionality.',
    'Scroll down to the "Barcode Printers" section and click on the "Test Printer" button to verify barcode printer functionality.',
  ];
  return (
    <Grid container spacing={1} className={styles.setupContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center !important' }}>
        <Typography variant="button" fontWeight={600}>
          Test Printer
        </Typography>
        <Typography className={styles.subText}>
          Now that you've configured your printer settings, follow these steps
          to test your printers:
        </Typography>
      </Grid>
      <StepList steps={testPrinterSteps} />
    </Grid>
  );
};

const AddProducts = () => {
  const handleAddProductClick = () => {
    window.open(`${window.location.origin}/console/items`, '_blank');
  };
  const addProductFromWeb = [
    <React.Fragment key="viewReportStep">
      Click on "Items" in the left navigation bar and In the "Subsection," click
      on "Products" or click{' '}
      <Button
        variant="contained"
        className={styles.btn}
        sx={{ padding: '4px 15px !important' }}
        onClick={handleAddProductClick}
      >
        Add Product
      </Button>
    </React.Fragment>,
    'On the Products page, click the "NEW ITEM" button in the top right corner.',
    'In the popup, add the necessary fields and click "CREATE ITEM"',
    'You can also add products by importing csv file',
  ];
  const addProductsFromApp = [
    'Click on "More" in the top right corner.',
    'In the list, click on "Manage. On the Manage page, click on "Products"',
    'In the Products section, click "More" in the top right corner.',
    ' In the list, click on "Create Item". Fill in the necessary fields and click "Save"',
  ];

  return (
    <Grid container spacing={1} className={styles.setupContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center !important' }}>
        <Typography variant="button" fontWeight={600}>
          Add Products
        </Typography>
        <Typography className={styles.subText}>
          Now, let's add products to your inventory. Follow these steps based on
          your platform:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="button">For Web:</Typography>
      </Grid>
      <StepList steps={addProductFromWeb} />
      <Grid item xs={12}>
        <Typography variant="button">For App:</Typography>
      </Grid>
      <StepList steps={addProductsFromApp} />
    </Grid>
  );
};

const InvoiceCreation = () => {
  const createInvoice = [
    `In the POS App, navigate to the "Products" section. You'll see a list of products categorized for easy access.`,
    'Click on the products you want to add to the invoice. You can switch between different categories to find specific items.',
    'Click on the green button in the left bottom corner to view the selected products with their prices.',
    'On the new page, review all the products added to the cart. Here, you can edit item prices, quantities and add discounts etc.',
    'Click the "Bill" button in the right bottom corner to generate the invoice.',
  ];
  return (
    <Grid container spacing={1} className={styles.setupContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center !important' }}>
        <Typography variant="button" fontWeight={600}>
          Create Invoice
        </Typography>
        <Typography className={styles.subText}>
          Open the POS App and follow these steps to create an invoice:
        </Typography>
      </Grid>
      <StepList steps={createInvoice} />
    </Grid>
  );
};

const ViewReports = () => {
  const handleViewReportClick = () => {
    window.open(
      `${window.location.origin}/console/report/sale-summary`,
      '_blank'
    );
  };
  const viewReportsInWeb = [
    <React.Fragment key="viewReportStep">
      On the web, click on "Reports" in the left navigation bar or click{' '}
      <Button
        variant="contained"
        className={styles.btn}
        sx={{ padding: '4px 15px !important' }}
        onClick={handleViewReportClick}
      >
        View Report
      </Button>
    </React.Fragment>,
    `You'll see a list of different types of reports like item summary, bill summary, and bill itemized reports.`,
    'Navigate to the specific report type you need, set the filter for the date, and click the "Generate" button.',
  ];
  const viewReportsInApp = [
    `Log in to the Admin App. In the app, you'll find a list of days with the amount and number of bills.`,
    'Click on the "View Report" button next to the desired day to move to the report page. It will show all invoices for that day.',
  ];

  return (
    <Grid container spacing={1} className={styles.setupContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center !important' }}>
        <Typography variant="button" fontWeight={600}>
          View Reports
        </Typography>
        <Typography className={styles.subText}>
          To view and analyze invoice reports, follow these steps:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="button">For Admin Web:</Typography>
      </Grid>
      <StepList steps={viewReportsInWeb} />
      <Grid item xs={12}>
        <Typography variant="button">For Admin App:</Typography>
      </Grid>
      <StepList steps={viewReportsInApp} />
      <Grid item xs={12} className={styles.steps}>
        <Typography className={styles.subText}>
          Note: If you don't see the invoice you made, it may not be synced to
          the cloud. To sync, go to POS App, click on "More," then "Report," and
          scroll down to the Sales Report section. If there is a list of
          unsynced invoices, click on "SYNC SALE" or "FORCE SYNC."
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AppSetup;
