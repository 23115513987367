import BackendService from './BackendService';

async function getVendorsWithCount(params) {
  return BackendService.get('/vendors/', {}, params);
}
async function getVendors(params) {
  return BackendService.getData('/vendors/', {}, params);
}
async function getVendor(id) {
  return BackendService.getData(`/vendors/${id}/`);
}
async function getVendorJournals(id, params) {
  return BackendService.get(`/vendors/${id}/journals/`, {}, params);
}
async function createVendor(data) {
  return BackendService.postData('/vendors/', data);
}
async function updateVendor(id, data = {}) {
  return BackendService.patch(`/vendors/${id}/`, data);
}
async function deleteVendor(id) {
  return BackendService.destroy(`/vendors/${id}/`, '');
}
async function addPaymentToVendor(id, data) {
  return BackendService.post(`/vendors/${id}/journals/`, data);
}

const VendorServices = {
  getVendors,
  getVendorsWithCount,
  getVendor,
  getVendorJournals,
  createVendor,
  updateVendor,
  deleteVendor,
  addPaymentToVendor,
};

export default VendorServices;
