import React, { useState, useEffect, Fragment } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Grid, Button } from '@mui/material';
import SubscriptionService from '../../services/SubscriptionService';
import { STORAGE_KEYS } from '../../const';
import Loader from '../utils/Loading';
import { sentenceCase } from 'change-case';

function KeySignupPopup({ handleClose, handleKeyRegister }) {
  const [key, setKey] = useState();
  const [message, setMessage] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [prevKey, setPrevKey] = useState(null);
  const [loading, setLoading] = useState(false);

  const verifyKeyHandler = async () => {
    setMessage(null);
    setLoading(true);
    try {
      if (!key || key.length < 10) {
        setMessage({ error: 'Please enter a valid key.' });
        setLoading(false);
        return;
      }
      const resp = await SubscriptionService.verifyRegistrationKey(key);
      setMessage({ success: 'Token verified.' });
      setTokenData(resp?.plans);
      setPrevKey(key);
      localStorage.setItem(STORAGE_KEYS.REGISTRSATION_KEY, key);
    } catch (error) {
      setMessage({ error: error.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!tokenData || key === prevKey) return;
    setTokenData(null);
    //eslint-disable-next-line
  }, [key, prevKey]);

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper} style={{ paddingBottom: 5 }}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Sign up with key</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <span className={styles.subTextSec}>
                Verify your key. If it is valid, it will display the subscriptions and add-ons available. These options
                are specified and not changeable.
              </span>
            </Grid>
            <Grid item xs={12} display="flex">
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                type="text"
                value={key || ''}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
              <Button
                variant="contained"
                color="success"
                size="small"
                style={{
                  marginLeft: 10,
                  padding: '0px 20px',
                  backgroundColor: '#00a65a',
                }}
                onClick={verifyKeyHandler}
              >
                {!loading && 'Verify'}
                <Loader isOpen={loading} size={18} />
              </Button>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              {message ? (
                <span className={styles.errorMsg}>{message?.error}</span>
              ) : (
                <span className={styles.successMsg}>{message?.success}</span>
              )}
            </Grid>
            {tokenData && (
              <Grid item container rowSpacing={2} xs={12}>
                <Grid item xs={12}>
                  <span className={styles.editTitle}>Subscription</span>
                </Grid>
                <Grid item container rowSpacing={1} xs={12}>
                  <Grid item xs={6}>
                    <span className={styles.text}>Name</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className={styles.text}>{sentenceCase(tokenData?.subscription?.key)}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className={styles.text}>Validity</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className={styles.text}>{tokenData?.subscription?.validity} days</span>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <span className={styles.editTitle}>Add-ons</span>
                </Grid>
                <Grid item container rowSpacing={1} xs={12}>
                  {(tokenData?.add_ons || []).map((addon) => {
                    if (addon?.validity <= 0) return null;
                    return (
                      <Fragment key={addon.key}>
                        <Grid item xs={6}>
                          <span className={styles.text}>{sentenceCase(addon?.key)}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={styles.text}>{addon?.validity} days</span>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Button fullWidth variant="contained" onClick={() => handleKeyRegister(tokenData)}>
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default KeySignupPopup;
