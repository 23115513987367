import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import {
  TextField,
  Grid,
  InputAdornment,
  Box,
  Button,
} from '@material-ui/core';
import AuthService from '../../../services/AuthService';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { STORAGE_KEYS, ROUTES } from '../../../const';
import usernameIcon from './person.svg';
import passwordIcon from './lock.svg';
import Loader from '../../utils/Loading';
// import google from './google.png';
// import facebook from './facebook.png';
// import twiter from './twiter.png';
import { ThemeContext } from '../../../Context/ThemeContext';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (token) {
      history.push(ROUTES.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginBtnPressed = async () => {
    setErrorMessage(null);
    if (!username || !password) {
      setErrorMessage('Username and Password should not be empty.');
      return;
    }
    setLoadingIndicator(true);
    const error = await AuthService.login(username, password);
    setLoadingIndicator(false);
    if (error) {
      setErrorMessage('Failed to login!');
    } else {
      history.push(ROUTES.DASHBOARD);
    }
  };

  return (
    <Grid
      style={{ backgroundColor: theme.color.bg_color }}
      className={styles.mainDiv}
    >
      <Box
        container
        style={{ borderColor: theme.color.header }}
        className={styles.outerBoxFrame}
      >
        {/* <Hidden xsDown>
          <Grid item xs={12} sm={12} className={styles.formBoxFrame}>
            <Grid className={styles.welcome}>
              <span style={{ color: '#3F8DCE' }}>SANOFT</span>
              <span style={{ color: '#3C465B', marginLeft: 10 }}>POS</span>
            </Grid>
            <Grid className={styles.paddingp} style={{ textAlign: 'center' }}>
              <h5>TOTAL WEIGHING SOLUTIONS</h5>
              <p
                style={{ color: '#404040', lineHeight: 1.5, textAlign: 'left' }}
              >
                . Bill Printing Scale
                <br />
                . Label Printing Scale
                <br />
                . Bench Price Computing Scale
                <br />
                . Price Computing Scale ATM Keypad
                <br />
              </p>
            </Grid>
            <Grid className={styles.btnKnwon}>
              <Button
                size="small"
                variant="contained"
                onClick={() => window.open('http://www.weighvox.com', '_blank')}
              >
                Known More
              </Button>
            </Grid>
          </Grid>
        </Hidden> */}
        <Grid
          style={{ boxShadow: 3 }}
          item
          xs={12}
          sm={12}
          className={styles.formBoxFrame}
        >
          <Grid>
            <Grid className={styles.welcome}>
              <span style={{ color: theme.color.header }}>
                {theme.variable.name}
              </span>
              <span style={{ color: '#3C465B', marginLeft: 10 }}>POS</span>
            </Grid>
            <div className={styles.textCenter}>
              <TextField
                name="username"
                size="small"
                label="Username"
                type="text"
                placeholder="type your username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={usernameIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                autoComplete="current-username"
                color="primary"
                fullWidth
              />
            </div>
            <div className={styles.textCenter}>
              <TextField
                name="password"
                size="small"
                label="Password"
                type="password"
                margin="normal"
                placeholder="type your password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={passwordIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                autoComplete="current-password"
                color="primary"
                fullWidth
              />
            </div>
            <div className={styles.errorMsg}>
              {errorMessage && <span>{errorMessage}</span>}
            </div>
            {/* <div className={styles.loginLoader}>
              {loadingIndicator && <Loader />}
            </div> */}
            <Loader isOpen={loadingIndicator} />
            <Grid className={styles.forgotPasswordWrapper}>
              <Link to="/reset-password" className={styles.forgotPassword}>
                Forgot password?
              </Link>
            </Grid>
            <Grid className={styles.textCenter}>
              <Button
                className={styles.loginButton}
                variant="contained"
                type="submit"
                onClick={loginBtnPressed}
                disabled={loadingIndicator}
                fullWidth
                style={{
                  backgroundColor: theme.color.header,
                  color: 'white',
                }}
              >
                Login
              </Button>
            </Grid>

            {/*<Grid*/}
            {/*  container*/}
            {/*  justifyContent={'center'}*/}
            {/*  className={styles.socialSignInWrapper}*/}
            {/*>*/}
            {/*  <Grid className={styles.socialMeadiaLogin}>*/}
            {/*    <img alt="" src={facebook} width="25" height="25" />*/}
            {/*  </Grid>*/}
            {/*  <Grid className={styles.socialMeadiaLogin}>*/}
            {/*    <img alt="" src={google} width="25" height="25" />*/}
            {/*  </Grid>*/}
            {/*  <Grid className={styles.socialMeadiaLogin}>*/}
            {/*    <img alt="" src={twiter} width="25" height="25" />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}

            <Grid className={styles.signupHintWrapper}>
              <span>Doesn't have an account? </span>
              <Link to={ROUTES.PRICING}>Register</Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
