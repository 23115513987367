import BackendService from './BackendService';
import { HEADERS, STORAGE_KEYS } from '../const';

async function login(username, password) {
  const reqData = { username, password };
  try {
    const resp = await BackendService.postData('/login/', reqData, {
      'User-Interface': 'ios',
    });
    if (!resp.token) {
      return new Error('Failed generate auth token from server');
    }
    localStorage.setItem(STORAGE_KEYS.TOKEN, resp.token);
  } catch (error) {
    return error;
  }
}
async function logout(data = {}) {
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
  localStorage.clear();

  if (!token) return;
  try {
    return BackendService.destroy('/logout/', null, {
      [HEADERS.AUTH]: `Token ${token}`,
    });
  } catch (error) {
    console.error(error);
  }
}
async function resetPassword(data) {
  return BackendService.postData('/resetpassword/', data);
}
async function createAccount(data, registrationType) {
  try {
    const resp = await BackendService.postData(`/register/?shop_type=${registrationType}`, data);
    if (!resp.token) {
      return new Error(
        'Failed to register, please contact our support for help.'
      );
    }
    localStorage.setItem(STORAGE_KEYS.TOKEN, resp.token);
    localStorage.setItem(STORAGE_KEYS.SHOP_ID, resp?.shop?.id)
  } catch (error) {
    return error;
  }
}

const findCountryCode = () => {
  return BackendService.getData('/public/country-code/');
};

const AuthService = {
  login,
  logout,
  resetPassword,
  createAccount,
  findCountryCode,
};

export default AuthService;
