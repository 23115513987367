import BackendService from './BackendService';

async function getSalesByDate(data = {}) {
  return BackendService.postData('/reports/by_date/', data);
}

async function getSalesSummary(params = {}) {
  return BackendService.getData('/reports/summary/', {}, params);
}

const SalesReportsService = {
  getSalesByDate,
  getSalesSummary,
};

export default SalesReportsService;
