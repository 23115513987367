import BackendService from './BackendService';

async function getPurchaseDetails(params) {
    return BackendService.getData('/items/purchases/', {}, params);
}
async function addPurchase(data = {}) {
    return BackendService.postData('/items/purchases/', data);
}
async function getPurchase(id) {
    return BackendService.getData(`/items/purchase/${id}/`, '');
}
async function updatePurchase(data, id) {
    return BackendService.put(`/items/purchase/${id}/`, data);
}
async function deletePurchase(id) {
    return BackendService.destroy(`/items/purchase/${id}/`, '');
}
const PurchaseService = {
    getPurchaseDetails,
    addPurchase,
    getPurchase,
    updatePurchase,
    deletePurchase,
};

export default PurchaseService;