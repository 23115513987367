import BackendService from './BackendService';
import { HEADERS, STORAGE_KEYS } from '../const';
import CacheHelper from '../helpers/CacheHelper';

const cache = {};

async function getGstPreference() {
  const resp = await BackendService.getData('/settings/gst_preference/');
  return resp.gst_preference;
}
async function updateGstPreference(data) {
  return BackendService.postData('/settings/gst_preference/', data);
}
async function getShopSettings() {
  return BackendService.getData('/shop_profile/');
}
async function updateShopSettings(data = {}) {
  return BackendService.postData('/shop_profile/', data);
}
async function getGeKartKey() {
  return BackendService.getData('/settings/gekart_sync/key/');
}
async function updateGeKartKey(data) {
  return BackendService.postData('/settings/gekart_sync/key/', data);
}
async function getExternalTokenKey() {
  return BackendService.getData('/settings/external_token/');
}
async function resetExternalTokenKey() {
  return BackendService.put('/settings/external_token/');
}
async function updateBillPrinterIP(data) {
  return BackendService.postData('/settings/bill_printer_ip/', data);
}
async function updatePointsToRupee(data) {
  return BackendService.postData('/settings/reward_points/', data);
}
async function getSubscriptionPlans() {
  return BackendService.getData('/subscriptions/');
}
async function initiatePayment(data) {
  return BackendService.postData('/subscriptions/payments/', data);
}
async function loadShopProfile(
  shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID)
) {
  if (!shopId) return;

  const cacheKey = `shop-${shopId}-shop-profile`;
  const cachedShopProfile = CacheHelper.get(cache, cacheKey);
  if (cachedShopProfile) {
    return cachedShopProfile;
  }

  const shopProfile = await BackendService.getData('/shop_profile/', {
    [HEADERS.SHOP_ID]: shopId,
  });
  CacheHelper.store(cache, cacheKey, shopProfile);
  return shopProfile;
}
async function getUnappliedSubscription(data = {}) {
  return BackendService.getData('/subscriptions/payments/unused/', data);
}
async function activatePlan(data = {}) {
  return BackendService.postData('/subscriptions/payments/activate/', data);
}
async function getPaymentModes(params) {
  return BackendService.getData('/payment-modes/', {}, params);
}
async function updatePaymentModes(id, data) {
  return BackendService.patch(`/payment-modes/${id}/`, data);
}
async function createPaymentMode(data) {
  return BackendService.postData('/payment-modes/', data);
}
async function uploadShopLogo(data) {
  return BackendService.postData(`/shops/settings/logo/`, data);
}
async function attachQuickbookAccount(data) {
  return BackendService.postData('/shops/settings/quickbook/attach/', data);
}
async function detachQuickbookAccount() {
  return BackendService.destroy('/shops/settings/quickbook/detach/');
}
async function fetchQuickbookProducts() {
  return BackendService.get('/quickbooks/products/fetch/');
}
async function getExternalSyncFailedSales() {
  return BackendService.getData('/external-sales-sync/failed-logs/');
}
async function syncAllExternalSales() {
  return BackendService.getData('/external-sales-sync/sync/');
}
async function syncExternalSale(id) {
  return BackendService.getData(`/external-sales-sync/sync/${id}/`);
}

const ShopSettingsService = {
  getGeKartKey,
  updateGeKartKey,
  getGstPreference,
  updateGstPreference,
  getExternalTokenKey,
  resetExternalTokenKey,
  updateBillPrinterIP,
  updatePointsToRupee,
  getSubscriptionPlans,
  initiatePayment,
  loadShopProfile,
  getUnappliedSubscription,
  activatePlan,
  getShopSettings,
  updateShopSettings,
  getPaymentModes,
  updatePaymentModes,
  createPaymentMode,
  uploadShopLogo,
  attachQuickbookAccount,
  detachQuickbookAccount,
  fetchQuickbookProducts,
  getExternalSyncFailedSales,
  syncAllExternalSales,
  syncExternalSale,
};

export default ShopSettingsService;
