import BackendService from './BackendService';

async function getSubscriptionsAndAdons(country) {
  return BackendService.getData(
    `/subscription-and-addons/?country_code=${country}`
  );
}

async function verifyRegistrationKey(secret) {
  return BackendService.postData('/registration-keys/verify/', { secret });
}

const SubscriptionService = {
  getSubscriptionsAndAdons,
  verifyRegistrationKey,
};

export default SubscriptionService;
