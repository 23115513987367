import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import TerminalService from '../../../services/TerminalServices';
import FeatureNotAvailableDialog from '../../popups/FeatureNotAvailableDialog';
import { AlertMessagesContext } from 'react-alert-messages';
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  FormGroup,
  Checkbox,
  Button,
} from '@mui/material';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import { ShopContext } from '../../../Context/ShopContext';
import {
  ORDER_TYPES,
  BARCODE_PRINTER_FORMATS,
  BARCODE_PRINTER_TYPES,
  KOT_FORMATS,
  INVOICE_FORMATS,
  PRINTER_TYPES,
  SUFFIX_OPTIONS,
} from '../../../const';

function TerminalSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [featureNotAvailable, setFeatureNotAvailable] = useState(false);
  const [terminal, setTerminal] = useState(null);
  const [disableMacidEdit, setDisableMacidEdit] = useState(false);

  const { terminalId } = useParams();
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const getTerminal = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await TerminalService.getTerminal(terminalId);
      setTerminal(res);
      if(res?.mac_id) {
        setDisableMacidEdit(true);
      }
    } catch (error) {
      postAlertMessage({ text: 'Attempt failed.', type: 'failed' });
    }
    setIsLoading(false);
  }, [terminalId, postAlertMessage]);

  const updateTerminal = async () => {
    setIsLoading(true);
    try {
      const res = await TerminalService.updateTerminals(terminalId, terminal);
      setTerminal(res);
      postAlertMessage({
        text: 'Updated terminal successfully',
        type: 'success',
      });
      if(res?.mac_id) {
        setDisableMacidEdit(true);
      }
    } catch (error) {
      postAlertMessage({ text: 'Attempt failed.', type: 'failed' });
    }
    setIsLoading(false);
  };

  const featureIsNotAvailable = (validity, feature) => {
    if (featureIsExpired(validity)) {
      setFeatureNotAvailable(feature);
    }
  };

  const orderTypeHandler = (event) => {
    const { name } = event.target;
    let orderTypes;

    if (terminal?.order_types !== null) {
      orderTypes = terminal?.order_types.split(',');
    } else {
      orderTypes = [];
    }

    if (orderTypes.includes(name)) {
      orderTypes.pop(name);
    } else {
      orderTypes.push(name);
    }

    let activeOrderTypes = orderTypes.join(',');
    setTerminal({ ...terminal, order_types: activeOrderTypes });
  };

  const isCheckedOrderType = (order_type) => {
    if (
      terminal?.order_types !== null &&
      typeof terminal?.order_types === 'string'
    ) {
      return terminal?.order_types.split(',').includes(order_type);
    } else {
      return false;
    }
  };

  const handleChangeMode = (event) => {
    const { name, checked } = event.target;
    const updatedData = {
      ...terminal,
      [name]: checked,
    };

    setTerminal(updatedData);
  };

  const closeDialogHandler = () => {
    setFeatureNotAvailable(null);
  };

  const disconnectTerminalhandler = async () => {
    setIsLoading(true);
    try {
      await TerminalService.disconnectTerminal(terminalId);
    } catch (error) {
      postAlertMessage({ text: 'Attempt failed.', type: 'failed' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTerminal();
  }, [getTerminal]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isLoading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Terminal <span className={styles.menuTitle}>Details</span>
        </h2>
      </div>
      <div className={styles.settingsGrid}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>ID</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled
                  value={terminal?.id}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Name</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => {
                    setTerminal({ ...terminal, name: e.target.value });
                  }}
                  value={terminal?.name}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Type</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled
                  value={terminal?.type}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Mac ID</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => {
                    setTerminal({ ...terminal, mac_id: e.target.value });
                  }}
                  disabled={disableMacidEdit}
                  value={terminal?.mac_id}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Access code</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => {
                    setTerminal({ ...terminal, access_code: e.target.value });
                  }}
                  value={terminal?.access_code}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Admin code</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => {
                    setTerminal({ ...terminal, admin_code: e.target.value });
                  }}
                  value={terminal?.admin_code}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Location</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="id"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled
                  onChange={(e) => {
                    setTerminal({ ...terminal, location: e.target.value });
                  }}
                  value={terminal?.location}
                />
              </Grid>
            </Grid>
          </Grid>
          {terminal?.type === 'ecs' && (
            <>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Connected Host</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="id"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled
                      value={terminal?.connected_host}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Connected Server Port
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="id"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled
                      value={terminal?.connected_server_port}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Communication Port
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="id"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled
                      value={terminal?.connected_comm_port}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Next FID</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="id"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      value={terminal?.next_sale_number_to_load}
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          next_sale_number_to_load: event.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Is active</span>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={terminal?.is_active || false}
                      // onChange={handleChangeTerminalMode}
                      // name={'is_acitve'}
                      disabled
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={styles.titleSec} style={{ marginTop: 20 }}>
        <h2 className={styles.title}>
          Terminal <span className={styles.menuTitle}>settings</span>
        </h2>
      </div>
      <div className={styles.settingsGrid}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={styles.subHeader}>
            <span>Invoice Config</span>
          </Grid>
          <Grid item xs={12} lg={6} className={styles.BillHeader}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className={styles.fieldTitle}>Invoice Number Prefix</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="invoice_no_prefix"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(event) => {
                    setTerminal({
                      ...terminal,
                      invoice_no_prefix: event.target.value,
                    });
                  }}
                  value={terminal?.invoice_no_prefix}
                />
              </Grid>
            </Grid>
          </Grid>
          {terminal?.type === 'ecs' && (
            <>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Suffix Type For Bill Number
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      name="printerType"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          suffix_type_for_bill_number: event.target.value,
                        });
                      }}
                      value={terminal?.suffix_type_for_bill_number || 'FID'}
                    >
                      {SUFFIX_OPTIONS.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {terminal?.type === 'pos' && (
            <>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Token Number Prefix
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="printerType"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          token_no_prefix: event.target.value,
                        });
                      }}
                      value={terminal?.token_no_prefix}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={styles.subHeader}>
                <span>POS Config</span>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Show Payment Page</span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.show_payment_page || false}
                          onChange={handleChangeMode}
                          name={'show_payment_page'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Show MRP Column On Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.show_mrp_column_on_invoice || false
                          }
                          onChange={handleChangeMode}
                          name={'show_mrp_column_on_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Counter Foil With Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.counter_foil_with_invoice || false}
                          onChange={handleChangeMode}
                          name={'counter_foil_with_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Show Payment Mode On Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.show_payment_mode_on_invoice || false
                          }
                          onChange={handleChangeMode}
                          name={'show_payment_mode_on_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Print Total Save On Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.print_total_save_on_invoice || false
                          }
                          onChange={handleChangeMode}
                          name={'print_total_save_on_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Skip Payment Page On Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.skip_payment_page_on_invoice || false
                          }
                          onChange={handleChangeMode}
                          name={'skip_payment_page_on_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Order With Full Payment Only
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.order_with_full_payment_only || false
                          }
                          onChange={handleChangeMode}
                          name={'order_with_full_payment_only'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Print Invoice On Payment Update
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.print_invoice_on_payment_update || false
                          }
                          onChange={handleChangeMode}
                          name={'print_invoice_on_payment_update'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Price With Tax In Invoice
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.price_with_tax_in_invoice || false}
                          onChange={handleChangeMode}
                          name={'price_with_tax_in_invoice'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Amount With Three Fraction
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.amount_with_three_fraction || false
                          }
                          onChange={handleChangeMode}
                          name={'amount_with_three_fraction'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Salesperson Login</span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.salesperson_login || false}
                          onChange={handleChangeMode}
                          name={'salesperson_login'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Advanced Quantity Control
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.advanced_quantity_control || false}
                          onChange={handleChangeMode}
                          name={'advanced_quantity_control'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Bluetooth Scale Weight Confirmation
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.confirm_bluetooth_scale_weight || false
                          }
                          onChange={handleChangeMode}
                          name={'confirm_bluetooth_scale_weight'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Enable Table Selection POS
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            terminal?.enable_table_selection_pos || false
                          }
                          onChange={handleChangeMode}
                          name={'enable_table_selection_pos'}
                          color="primary"
                          disabled={featureIsExpired(shop?.kot_validity)}
                        />
                      }
                      onClick={() =>
                        featureIsNotAvailable(shop?.kot_validity, 'KOT')
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Print kot with bill
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={terminal?.kot_with_bill || false}
                          onChange={handleChangeMode}
                          name={'kot_with_bill'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={3}>
                    <span className={styles.fieldTitle}>Order Types</span>
                  </Grid>
                  <Grid item xs={6} lg={9}>
                    <FormGroup row={true}>
                      {ORDER_TYPES.map((order_type) => {
                        let [key, value] = Object.entries(order_type)[0];
                        return (
                          <FormControlLabel
                            key={key}
                            style={{ marginRight: '5%' }}
                            control={
                              <Checkbox
                                checked={isCheckedOrderType(value)}
                                name={value}
                                color="primary"
                                onChange={orderTypeHandler}
                              />
                            }
                            label={key}
                          />
                        );
                      })}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>UPI ID</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          upi_id: event.target.value,
                        });
                      }}
                      value={terminal?.upi_id}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Number Of Shift For Store History
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      name="number_of_shifts_to_be_stored_on_device"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          number_of_shifts_to_be_stored_on_device:
                            event.target.value,
                        });
                      }}
                      value={terminal?.number_of_shifts_to_be_stored_on_device}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={styles.subHeader}>
                <span>Printer Config</span>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Printer Type</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      name="printerType"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          printer_type: event.target.value,
                        });
                      }}
                      value={terminal?.printer_type || 'USB'}
                    >
                      {PRINTER_TYPES.map((printer_type) => (
                        <MenuItem key={printer_type} value={printer_type}>
                          {printer_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>Invoice Format</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      name="invoiceFormat"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          invoice_format: event.target.value,
                        });
                      }}
                      value={terminal?.invoice_format || '3 Inch Image'}
                    >
                      {INVOICE_FORMATS.map((invoice_format) => (
                        <MenuItem key={invoice_format} value={invoice_format}>
                          {invoice_format}
                        </MenuItem>
                      ))}
                      {(terminal?.printer_type === 'PDF' ||
                        terminal?.printer_type === 'Whatsapp') && (
                        <MenuItem key={'Share'} value={'Share'}>
                          {'Share'}
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Kitchen KOT Format
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={() =>
                      featureIsNotAvailable(shop?.kot_validity, 'KOT')
                    }
                  >
                    <TextField
                      select
                      name="kitchenKotFomat"
                      size="small"
                      variant="outlined"
                      disabled={featureIsExpired(shop?.kot_validity)}
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          kitchen_kot_format: event.target.value,
                        });
                      }}
                      value={terminal?.kitchen_kot_format || '3 Inch'}
                    >
                      {KOT_FORMATS.map((kot_format) => (
                        <MenuItem key={kot_format} value={kot_format}>
                          {kot_format}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Consolidated KOT Format
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={() =>
                      featureIsNotAvailable(shop?.kot_validity, 'KOT')
                    }
                  >
                    <TextField
                      select
                      name="consolidatedKotFomat"
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled={featureIsExpired(shop?.kot_validity)}
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          consolidated_kot_format: event.target.value,
                        });
                      }}
                      value={terminal?.consolidated_kot_format || '3 Inch'}
                    >
                      {KOT_FORMATS.map((kot_format) => (
                        <MenuItem key={kot_format} value={kot_format}>
                          {kot_format}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      KOT With Invoice Printer IP
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ position: 'relative' }}>
                    {featureIsExpired(shop?.kot_validity) && (
                      <div
                        className={styles.showDialog}
                        onClick={() =>
                          featureIsNotAvailable(shop?.kot_validity, 'KOT')
                        }
                      />
                    )}
                    <TextField
                      name="barcodePrinterFormat"
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled={featureIsExpired(shop?.kot_validity)}
                      fullWidth
                      onClick={() =>
                        featureIsNotAvailable(shop?.kot_validity, 'KOT')
                      }
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          kot_with_invoice_printer_ip: event.target.value,
                        });
                      }}
                      value={terminal?.kot_with_invoice_printer_ip}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Consolidated KOT Printer IP
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ position: 'relative' }}>
                    {featureIsExpired(shop?.kot_validity) && (
                      <div
                        className={styles.showDialog}
                        onClick={() =>
                          featureIsNotAvailable(shop?.kot_validity, 'KOT')
                        }
                      />
                    )}
                    <TextField
                      name="barcodePrinterFormat"
                      size="small"
                      variant="outlined"
                      disabled={featureIsExpired(shop?.kot_validity)}
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          consolidated_kot_printer_ip: event.target.value,
                        });
                      }}
                      value={terminal?.consolidated_kot_printer_ip}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Barcode Printer Type
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      name="barcodePrinterType"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          barcode_printer_type: event.target.value,
                        });
                      }}
                      value={terminal?.barcode_printer_type || 'USB'}
                    >
                      {BARCODE_PRINTER_TYPES.map((barcode_printer_type) => (
                        <MenuItem
                          key={barcode_printer_type}
                          value={barcode_printer_type}
                        >
                          {barcode_printer_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Barcode Printer Format
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      name="barcodePrinterFormat"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          barcode_printer_format: event.target.value,
                        });
                      }}
                      value={terminal?.barcode_printer_format || '38 X 25'}
                    >
                      {BARCODE_PRINTER_FORMATS.map((barcode_printer_format) => (
                        <MenuItem
                          key={barcode_printer_format}
                          value={barcode_printer_format}
                        >
                          {barcode_printer_format}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Barcode Custom Note
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="barcodePrinterNote"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          barcode_custom_note: event.target.value,
                        });
                      }}
                      value={terminal?.barcode_custom_note}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Printer segment height
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="barcodePrinterNote"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          printer_segment_height: event.target.value,
                        });
                      }}
                      value={terminal?.printer_segment_height}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Printer segment delay
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="barcodePrinterNote"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          printer_segment_delay: event.target.value,
                        });
                      }}
                      value={terminal?.printer_segment_delay}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Printer cutter height
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="barcodePrinterNote"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          printer_cutter_height: event.target.value,
                        });
                      }}
                      value={terminal?.printer_cutter_height}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className={styles.BillHeader}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className={styles.fieldTitle}>
                      Printer cutter delay
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="barcodePrinterNote"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(event) => {
                        setTerminal({
                          ...terminal,
                          printer_cutter_delay: event.target.value,
                        });
                      }}
                      value={terminal?.printer_cutter_delay}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            className={styles.buttonWrapper}
            justifyContent="flex-end"
          >
            {terminal?.type === 'ecs' && (
              <Button
                variant="contained"
                color="error"
                className={styles.button}
                disabled={isLoading}
                onClick={disconnectTerminalhandler}
              >
                Disconnect
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              className={styles.button}
              disabled={isLoading}
              onClick={updateTerminal}
            >
              Update
            </Button>
          </Grid>
        </Grid>
        {featureNotAvailable && (
          <FeatureNotAvailableDialog
            closeHandler={closeDialogHandler}
            feature={featureNotAvailable}
          />
        )}
      </div>
    </div>
  );
}

export default withConsoleBase(TerminalSettings);
