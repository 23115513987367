import BackendService from './BackendService';

async function getItemSummaryReport(data = {}) {
  return BackendService.postData('/reports/item-summary/', data);
}
async function getItemSummaryReportWithProfit(data = {}) {
  return BackendService.postData('/reports/item-summary/profit/', data);
}
async function getItemSummaryExcel(data = {}) {
  return BackendService.post(
    '/reports/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}
async function getItemSummaryCsv(data = {}) {
  return BackendService.post(
    '/reports/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}
async function getVat(data = {}) {
  return BackendService.getData('/vat/', data);
}

const ItemSummaryReportService = {
  getItemSummaryReport,
  getItemSummaryExcel,
  getItemSummaryCsv,
  getVat,
  getItemSummaryReportWithProfit,
};

export default ItemSummaryReportService;
